
import React from "react"

const OrderCard =({OrderDetail}) =>{

return ( <div className="max-w-6xl mx-auto  bg-white shadow-md rounded-3xl">
            <div className="block ">
                    {OrderDetail?.order?.map((item) =>{
                        return  <div className="text-black p-6 border-b border-gray-300 flex items-center  ">
                                    <img
                                        src={item.img}
                                        alt="Reloj Minimalista"
                                        className="w-24 h-24 object-cover rounded-md"
                                        />
                                    <div className="">
                                        <h2 className="text-sm font-semibold text-gray-700 ">Producto:{item.Name}</h2>
                                        <p className="text-gray-500 text-sm ">Cantidad:{item.quantity}</p>
                                        <p className="text-gray-500 text-sm">Descripción:{item.Description}</p>
                                        <p className="mt-2 text-gray-500 font-bold text-sm ">${parseInt(item?.unit_price).toLocaleString("es-CO")}</p>
                                    </div>
                                </div>
                    })}
            </div>

            <div className="flex justify-between items-start " >
                    <div className=" p-6 ">
                        <h3 className="text-sm font-semibold text-gray-700">Dirección de entrega</h3>
                        <p className="text-gray-600  text-sm">{OrderDetail?.customer?.city}</p>
                        <p className="text-gray-600 text-sm">{OrderDetail?.customer?.address}</p>
                        <p className="text-gray-600 text-sm">{OrderDetail?.customer?.postalCode}</p>
                        <p className="text-gray-600 text-sm">{OrderDetail?.customer?.state}</p>
                    </div>

                    <div className="p-6" >
                        <h3 className="text-sm font-semibold text-gray-700 mt-4">Datos del cliente</h3>
                        <p className="text-gray-600">{OrderDetail?.customer?.Username} {OrderDetail?.customer?.Lastname}</p>
                        <p className="text-gray-600">{OrderDetail?.customer?.Email}</p>
                        <p className="text-gray-600">{OrderDetail?.customer?.Phone}</p>
                    
                    </div>
            </div>
                
            <div className="mt-6 p-6">
                <div className="flex justify-between text-sm font-semibold text-gray-600 mb-2">
                <span>Pedido realizado</span>
                <span>Procesando</span>
                <span>Enviado</span>
                <span>Entregado</span>
                </div>
                <div className="relative w-full h-2 bg-gray-200 rounded-full">
                <div className="absolute top-0 left-0 h-2 bg-blue-600 rounded-full" style={{ width: '50%' }}></div>
                </div>
            </div>
        </div>
    )  
}

export default OrderCard