import React, { useRef } from 'react'
import ShoppingCart from '../../Component/ShoppingCart/ShoppingCart';
import Footer from '../../Component/Footer/Footer';
import SliceProduct from '../../Component/CardProduct/SliceProduct';
import TitleWelcome from '../../Component/TitleWelcome/TitleWelcome';
import People from '../../Component/People/People';
import Gallery from '../../Component/Gallery/Gallery';
import Feature from '../../Component/Feature/Feature';
import Specifications from '../../Component/Specifications/Specifications';

const LoadingPage =() =>{

  const roomSectionRef = useRef(null);
     
  const scrollToRoomSection = () => {
      if (roomSectionRef.current) {
          roomSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
  };

  const features = [
    {
      title: "Cristal",
      image:
        "https://github.com/rolandoto/image-pms/blob/main/Captura%20de%20pantalla%202024-12-13%20a%20la(s)%201.11.39%20p.m..png?raw=true", // Imagen de Apple
     
    },
    {
      title: "Madera",
      image:
        "https://github.com/rolandoto/image-pms/blob/main/Captura%20de%20pantalla%202024-12-13%20a%20la(s)%201.17.36%20p.m..png?raw=true", // Imagen de Apple
     
    },
    {
      title: "Metal",
      subtitle: "Fast that lasts.",
      image:
        "https://github.com/rolandoto/image-pms/blob/main/Captura%20de%20pantalla%202024-12-13%20a%20la(s)%201.21.54%20p.m..png?raw=true", // Imagen de Apple
    },
    {
        title: "intemperie",
        image:
          "https://github.com/rolandoto/image-pms/blob/main/Captura%20de%20pantalla%202024-12-13%20a%20la(s)%201.25.34%20p.m..png?raw=true", // Imagen de Apple
      },
  ];



    return <>
    <ShoppingCart />
    <TitleWelcome scrollToRoomSection={scrollToRoomSection} />
    
    <p className="mx-auto mt-2 max-w-lg text-balance text-center text-4xl font-semibold tracking-tight text-gray-950 sm:text-5xl">
    Encuentra tú Cerradura ideal
    </p>
    <div className="flex flex-col md:flex-row gap-6 p-6">
  {features.map((feature, index) => (
    <div
      key={index}
      className="relative flex flex-col rounded-3xl overflow-hidden shadow-lg w-full md:w-1/3 h-[700px]"
    >
      {/* Imagen de fondo */}
      <img
        src={feature.image}
        alt={feature.title}
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <div className="relative z-10 text-white p-6">
        <h3 className="text-[50px] font-semibold uppercase tracking-wide">
          {feature.title}
        </h3>
      </div>
    </div>
  ))}
</div>


    <Gallery />
    <Feature />
    <Specifications />      
    <div ref={roomSectionRef} >
    <SliceProduct />    
    </div>
    <People />


    <Footer />
        </>

}

export default LoadingPage