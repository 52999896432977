   export const config = {
    serverRoute: "https://geco-backend-production.up.railway.app"
    //serverRoute: "http://localhost:3001"
  }
  export const SocketRoute = {
   serverRoute: "https://geco-backend-production.up.railway.app"
   //serverRoute: "http://localhost:3001"
  }
  export const CLoubedsRoute ={
   serverRoute: "https://geco-backend-production.up.railway.app"
   //serverRoute:"http://localhost:3001"
  }